body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#navbar {
  background-color: #c4bbbb;
  overflow: hidden;
  opacity: 0.2;
  position: fixed; /* Add this line */
  top: 0; /* Align to the top */
  width: 100%; /* Stretch across the full width of the viewport */
  z-index: 100; /* Ensure it stays above other content */
}

#navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#navbar li {
  float: left;
}

#navbar li a {
  display: block;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

#navbar li a:hover {
  background-color: #ddd;
  color: black;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  margin-bottom: 320px; /* Adjust the value as needed */
}

#gifBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/f666ce7b21152768e565ce0b0a3ce1ca.gif'); /* Replace with your GIF path */
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; /* Ensure it stays in the background */
}



